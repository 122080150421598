'use strict';
var $ = window.$;

$(document).ready(function () {
  sendForm();
  $.fancybox.defaults.touch = false;
  // $.fancybox.defaults.toolbar = false;
  $.fancybox.defaults.infobar = false;
  // $.fancybox.defaults.arrows = false;
  // $.fancybox.defaults.autoFocus = false;

  // Слайдеры
  $('[main-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');

    $init.slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: false,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
    });
  });

  $('[promo-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');

    $init.slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: false,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
    });
  });

  // Открытие мобильного меню
  $('.js-toggle-menu').on('click', function () {
    $('#mobile-menu').toggleClass('is-open');
    $('#hamburger').toggleClass('is-open');
  });

  $('.js-close-menu').on('click', function () {
    $('#mobile-menu').removeClass('is-open');
    $('#hamburger').removeClass('is-open');
  });

  // плавная прокрутка
  $('a[href*="#"]').on('click', function (event) {
    event.preventDefault();
    const id = $(this).attr('href');
    const top = $(id).offset().top;
    $('#mobile-menu').removeClass('is-open');
    $('body,html').animate({
      scrollTop: top,
    }, 500);
  });

  //  insta
  var feed = new Instafeed({
    accessToken: 'IGQVJVTTBQcWJKNVJiQzlhTUZAWYmdWcDF1MEZAkQ3lkRlNsWEdvQzI3M2NhS3c1SzFUN0V3TUFnNVJDVnFtWjM5dFVsdjlnMkNCcXhHZAnRNdTI3bGhFVWk4V1c3YkRuVFBqQTA3Nnh5T0JyZAjJXNXNxbQZDZD',
    limit: 8,
    template: '<a class="insta-feed__item" href="{{link}}"><div class="insta-feed__container"><img class="insta-feed__img" src="{{image}}" /></div></a>',
  });
  feed.run();

  function sendForm() {
    $('#callback-form').on('submit', function (event) {
      event.preventDefault();

      let appLink = 'https://script.google.com/macros/s/AKfycbyM7ftDIXsOyhEmw6KMluI3rbHinWJSymrguz1HhzCFFK5yrp7RFaopVAIGjOp18nI/exec';
      let form = $(this)[0];
      let submitButton = $(this).find('.callback-form__button');

      // FormData
      let fd = new FormData(form);

      $.ajax({
        url: appLink,
        type: 'POST',
        data: fd,
        processData: false,
        contentType: false,
        beforeSend: function () {
          submitButton.prop('disabled', true);
        },

      }).done(function (res, textStatus, jqXHR) {
        console.log(res);

        if (jqXHR.readyState === 4 && jqXHR.status === 200) {
          window.location.href = 'obrigado.html';
          submitButton.prop('disabled', false);
          form.reset();
        }
      }).fail(function (res, textStatus, jqXHR) {
        setTimeout(() => {
          submitButton.prop('disabled', false);
        }, 5000);
      });
    });
  }

  if (readCookie('close_cookie_window') !== 'true') {
    $('.cookies-window').removeClass('hide');
  }

  $('.js_close_cokies').on('click', function () {
    $('.cookies-window').addClass('hide');
    writeCookie('close_cookie_window', 'true', 5);
  });

  function writeCookie(name, val, expires) {
    const date = new Date;
    date.setDate(date.getDate() + expires);
    document.cookie = name+"="+val+"; path=/; expires=" + date.toUTCString();
  }

  function readCookie(name) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
});
